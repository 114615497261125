import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import img1 from "../images/marketing/svg/careers/our-culture-img1.png"
import img2 from "../images/marketing/svg/careers/our-culture-img2.png"
import img3 from "../images/marketing/svg/careers/our-culture-img3.png"
import img4 from "../images/marketing/svg/careers/our-culture-img4.png"
import img5 from "../images/marketing/svg/careers/our-culture-img5.png"
import img6 from "../images/marketing/svg/careers/our-culture-img6.png"
import img7 from "../images/marketing/svg/careers/our-culture-img7.png"


export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://www.ezrirx.com/careers/" />
      <title> EzriRx.com Marketplace Portal Is Hiring - Work With Us </title>
      <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
      <meta
        name="description"
        content="EzriRx allows pharmacies to compare drug prices offered by many suppliers and purchase from the most favorable. Browse, compare, and save an average of 30% on all drugs."
      />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section
          className="hero-banner hero-banner--only-text"
          id="hero-careers"
        >
          <div className="contents careers">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>Join our team</h1>
                <p>
                  {" "}
                  We’re building the most advanced pharmaceutical e-commerce
                  software in the world, and we’re looking for motivated people
                  like you to join us.
                </p>
                <a className="button move-to-section" href="#current-openings">
                  View current openings
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="ez-value">
          <div className="contents">
            <div className="testimonial-box has-quote">
              <div className="testimonial-box__content">
                <p className="mb-3">
                  It doesn't make sense to hire smart people and then tell them
                  what to do, we hire smart people so they can tell us what to
                  do
                </p>
              </div>
              <h4>
                <span className="name">Steve Jobs</span>
              </h4>
            </div>

            <div className="box-3cols clearfix">
              <div className="col">
                <div className="ez-matchHeight ez-value__item ez-value__item--transparency">
                  <h4>
                    We value
                    <strong>Transparency</strong>
                  </h4>

                  <p>
                    We believe in a transparent culture at EzriRx. When everyone
                    is on the same page, we can all move in the same direction
                    together and simply do better work.
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="ez-matchHeight ez-value__item ez-value__item--diversity">
                  <h4>
                    We value
                    <strong>Diversity</strong>
                  </h4>

                  <p>
                    We are dedicated to building a great team that includes
                    everyone - regardless of gender, sexual orientation, race,
                    ethnicity, education, age, or other personal
                    characteristics.
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="ez-matchHeight ez-value__item ez-value__item--balance">
                  <h4>
                    We value
                    <strong>Work-life balance</strong>
                  </h4>

                  <p>
                    We believe that your best work is done when you are living
                    your best life. That’s why we value the 5-day/40-hour work
                    week, and provide a casual work environment that encourages
                    a healthy and balanced lifestyle.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="col single-col">
              <div className="title-box">
                <h2>Our culture</h2>
              </div>
              <p>
                EzriRx is headquartered in New Jersey, but our team is spread
                out across the world and most of us work remotely in the places
                we thrive. We embrace the modern remote working culture, and we
                provide a calm and collected work environment where you can
                focus on doing your best work.
              </p>
            </div>
          </div>
        </section>

        <section className="ez-showcase">
          <div className="ez-showcase__left">
            <img src={img1} alt=""></img>
            <img src={img2} alt=""></img>
          </div>
          <div className="ez-showcase__center">
            <img src={img3} alt=""></img>
          </div>
          <div className="ez-showcase__right">
            <img src={img4} alt=""></img>
            <img src={img5} alt=""></img>
            <img src={img6} alt=""></img>
            <img src={img7} alt=""></img>
          </div>
        </section>

        <section id="current-openings" className="marketing-content">
          <div className="contents">
            <div className="title-box text-center">
              <h2>Current openings</h2>
              <p>Where do you see yourself at EzriRx?</p>
            </div>

            <div className="careers-list clearfix">
              <div className="intro-box careers-box has-margin-top no-job">
                <div className="careers-box__inner">
                  <div className="careers-box__info">
                    <p>
                      No jobs have been posted at the moment - check back soon!
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="intro-box careers-box has-margin-top">
                <div className="careers-box__inner">
                  <div className="careers-box__info">
                    <p><strong>Technical Support Engineer</strong></p>
                    <ul className="list-unstyled">
                      <li><img src={iconpointerimg} alt="" className="meta__image" Remote></img> Philippines</li>
                      <li><img src={iconclockimg} alt="" className="meta__image" Full Time></img> Full Time</li>
                    </ul>
                  </div>
                  <a href="https://www.linkedin.com/jobs/view/3009900721/" className="button with-border with-border--blue" target="_blank">Apply now</a>
                </div>
              </div>  */}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
